<template>
  <v-app>
    <v-sheet color="#F8ECDC" height="100%" class="pt-6">
      <v-row class="mt-12 mx-6">
        <v-col v-if="cartList.length === 0" cols="12" md="8">
          <v-card class="mt-5 py-6 d-flex align-center justify-center" height="472px">
            <div>
              <div class="text-center mt-12" style="font-size: 20px">
                <strong>カートが空です</strong>
              </div>
              <div style="height: 60px"></div>
              <div>
                <v-btn depressed style="background-color: #ff6e40" @click="subscriptions">
                  <span class="mx-3" style="font-size: 15px; color: #fff">お買物を続ける</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col v-if="cartList.length !== 0" cols="12" md="8">
          <v-card v-for="(product, index) in cartList" :key="index" class="px-3 my-8">
            <v-row>
              <v-col cols="12" md="4" lg="3" sm="6">
                <div style="height: 100%; width: 100%" class="d-flex align-center">
                  <v-img :src="product.load" class="d-flex align-center"></v-img>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="5" sm="6" class="mt-2">
                <div style="font-size: 18px; font-weight: bold">
                  <span>{{ product.name }}</span>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="12" class="mt-1">
                <v-card-actions style="width: 100%">
                  <v-btn
                    v-if="product.count == 1"
                    depressed
                    color="#ff6e40"
                    min-width="30%"
                    min-height="42px"
                    @click="del(product)"
                  >
                    <v-icon color="#fff"> mdi-minus </v-icon>
                  </v-btn>
                  <v-btn v-else depressed color="#ff6e40" min-width="30%" min-height="42px" @click="decrement(index)">
                    <v-icon color="#fff"> mdi-minus </v-icon>
                  </v-btn>
                  <div
                    class="text-center py-2"
                    style="border: 1px solid #2e2e22; border-radius: 5px; width: 40%; min-height: 42px"
                  >
                    {{ product.count }}
                  </div>
                  <v-btn depressed color="#ff6e40" min-width="30%" min-height="42px" @click="increment(index)">
                    <v-icon color="#fff"> mdi-plus </v-icon>
                  </v-btn>
                </v-card-actions>
                <div class="d-flex align-center justify-end my-3">
                  <span style="font-size: 14px">税込単価</span>
                  <span class="ml-2" style="font-size: 24px"
                    >￥{{
                      product.price_normal
                        ? product.price_normal.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                        : ''
                    }}</span
                  >
                </div>
                <div class="d-flex align-center justify-end my-3">
                  <span style="font-size: 14px">小計</span>
                  <span class="ml-2" style="font-size: 24px"
                    >￥{{
                      cal(product)
                        ? cal(product)
                            .toString()
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                        : ''
                    }}</span
                  >
                </div>
                <div class="d-flex justify-end">
                  <v-btn outlined @click="del(product)">
                    <span style="font-size: 13px">削除</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="my-5">
          <v-card class="px-6 my-3">
            <v-row class="pt-3" style="font-weight: bold; font-size: 20px">
              <v-col class="d-flex justify-start"> お支払い金額 </v-col>
            </v-row>
            <v-row style="font-weight: bold; font-size: 15px">
              <v-col class="d-flex justify-start">
                <span>商品名</span>
              </v-col>
              <v-col class="d-flex justify-end">
                <span>税込金額</span>
              </v-col>
            </v-row>
            <v-row v-for="(product, index) in cartList" :key="index" style="font-size: 15px">
              <v-col class="d-flex justify-start" cols="7" sm="8">
                {{ product.name }}
              </v-col>
              <v-col class="d-flex justify-end" cols="5" sm="4">
                {{ product.count }}x ￥{{
                  product.price_normal.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                }}
              </v-col>
            </v-row>
            <v-divider v-if="cartList.length != 0" class="my-8"></v-divider>
            <v-row style="font-size: 15px">
              <v-col class="d-flex justify-start"> 合計 </v-col>
              <v-col class="d-flex justify-end">
                ￥{{
                  total()
                    ? total()
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                    : ''
                }}
              </v-col>
            </v-row>
            <div class="mt-10">
              <v-btn depressed block color="#ff6e40" class="py-6" @click="pay">
                <span style="font-size: 15px; color: #fff">購入手続きへ</span>
              </v-btn>
              <v-dialog v-model="dialog" width="600px" persistent>
                <v-card height="180px" class="d-flex align-center justify-center">
                  <div>
                    <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
                      <span> 商品を追加してください。 </span>
                    </div>
                    <div class="d-flex justify-center mt-8">
                      <v-btn color="#ff6e40" width="180px" depressed @click="dialog = false">
                        <span style="color: #fff">OK</span>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <div class="pt-6 pb-8">
              <v-btn block outlined class="py-6" @click="subscriptions">
                <span style="font-size: 15px">お買物を続ける</span>
              </v-btn>
            </div>
            <v-divider class="mb-8"></v-divider>
            <div class="pb-12">
              <v-btn block outlined class="py-6" @click="delall(count)">
                <span style="font-size: 15px">カートを空にします</span>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="TipSet" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> お試しセットはお一人様一回限りです。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="TipSet = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="TipSubscription" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> 一回の注文につき最大２０個まで購入可能です。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="TipSubscription = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-sheet>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    dialog: false,
    TipSet: false,
    TipSubscription: false,
  }),
  computed: {
    ...mapState('cartStore', ['count', 'cartList']),
  },
  methods: {
    ...mapMutations('cartStore', ['increCount', 'decreCount', 'clearNavCount']),

    cal(product) {
      return product.price_normal * product.count
    },
    total() {
      let price = 0
      for (let i = 0; i < this.cartList.length; i += 1) {
        price += this.cartList[i].price_normal * this.cartList[i].count
      }

      return price.toFixed(0)
    },
    increment(index) {
      console.log('product_code:', this.cartList[index].product_code)
      if (this.cartList[index].count === 20) {
        this.TipSubscription = true
      } else {
        this.cartList[index].count += 1
        this.increCount()
      }
    },
    decrement(index) {
      if (this.cartList[index].count > 0) {
        if (this.cartList[index].count === 1) {
          this.del(this.cartList[index])
        }
        this.cartList[index].count -= 1
        this.decreCount()
      }
    },
    del(product) {
      const index = this.cartList.findIndex(p => p.product_code === product.product_code)
      this.cartList.splice(index, 1)
      this.clearNavCount(product.count)
    },
    delall(value) {
      this.cartList.splice(0, this.cartList.length)
      this.clearNavCount(value)
    },
    subscriptions() {
      this.$router.push('/')
    },
    pay() {
      if (this.cartList.length !== 0) {
        this.$router.push('/pay')
      } else {
        this.dialog = true
      }
    },
  },
}
</script>
